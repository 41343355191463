import request from '../../libs/axios'

export function getRoles(){
  return request({
    url: '/api/roles',
    method: 'get',
  })
}

export function createRolesById(){
  return request({
    url:'/api/roles',
    method:'get'
  })
}

export function getRolesById(data){
  return request({
    url: `/api/roles/${data.id}`,
    method: 'get',
  })
}


export function addRoles(data){
  return request({
    url: '/api/roles',
    method: 'post',
    data,
  })
}

export function editRoles(data){
  return request({
    url: `/api/roles/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteRoles(data){
  return request({
    url: `/api/roles/${data.id}`,
    method: 'delete'
  })
}

export function getRoleById(id) {
  return request({
    url: `/api/roles/${id}`,
    method:'get',
  })
}
