<template>
  <div>
    <b-overlay :show="loading">
      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" :name="$t('message.Name')" rules="required">
          <b-form-group :label="$t('message.Name')" for="name">
            <b-form-input autofocus v-model="branches.name"></b-form-input>
          </b-form-group>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider>

        <!-- <validation-provider #default="validationContext" :name="$t('message.Email')" rules="required|email">
          <b-form-group :label="$t('message.Email')" for="email">
            <b-form-input type="email" autofocus v-model="branches.email"></b-form-input>
          </b-form-group>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </validation-provider> -->

        <b-form-group :label="$t('message.Email')" for="email">
          <b-form-input v-model="branches.email"></b-form-input>
        </b-form-group>
        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
          <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
        </div>

      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addBranches,
    editBranches,
    getBranchesById
  } from '@/api/branchs/branchs'
  import {
    getRegionsById
  } from '@/api/regions/regions'

  export default {
    name: 'BranchForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      regions: '',
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        branches: {
          id: null,
          name: '',
          email: '',
          region_id: ''
        },
        disabled: false,
        loading: false,
      }
    },
    methods: {
      fetchBranches() {
        getRegionsById({
          id: this.id
        }).then(res => {
          this.branches = res.data.data
        })
      },
      handleSubmit() {
        const valid = this.$refs.refFormObserver.validate();
        if (valid) {
          this.disabled = true;
          this.loading = true;

          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
          },1000)

          if (this.id) {
            this.branches.id = this.id;
            // eslint-disable-next-line no-unused-vars
            editBranches(this.branches).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addBranches(this.branches).then(res => {
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchBranchById() {
        getBranchesById(this.id).then(res => {
          this.branches = res.data.data;
        })
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchBranchById()
            this.fetchBranches()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>