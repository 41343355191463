<template>
  <div>
    <b-overlay :show="loading">

      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" name="Full Name" rules="required">
          <b-form-group :label="$t('message.Name')" for="name">
            <b-form-input autofocus v-model="roles.name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Display Name" rules="required">
          <b-form-group :label="$t('message.Display_name')" for="displayname">
            <b-form-input autofocus v-model="roles.display_name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <!--      <div class="w-100 mb-2">-->
        <!--        <h5>Contacts</h5>-->
        <!--        <b-row v-for="(num, numIndex) in form.contacts" :key="numIndex" class="mt-2">-->
        <!--          <b-col cols="10">-->
        <!--            <validation-provider-->
        <!--                #default="validationContext"-->
        <!--                name="Full Name"-->
        <!--                rules="required"-->
        <!--            >-->
        <!--              <b-form-input autofocus v-model="num.number" class="w-100"></b-form-input>-->
        <!--              <b-form-invalid-feedback>-->
        <!--                {{ validationContext.errors[0] }}-->
        <!--              </b-form-invalid-feedback>-->
        <!--            </validation-provider>-->
        <!--          </b-col>-->
        <!--          <b-col cols="2">-->
        <!--            <b-button variant="outline-danger" size="sm" @click="removeNumber(numIndex)">-->
        <!--              <b-icon icon="trash"></b-icon>-->
        <!--            </b-button>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <hr>-->
        <!--        <div class="w-100 d-flex justify-content-end mt-2">-->
        <!--          <b-button variant="outline-success" size="sm" @click="increaseContact">-->
        <!--            <b-icon icon="plus"></b-icon>-->
        <!--          </b-button>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
          <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
        </div>
      </validation-observer>
    </b-overlay>

  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addRoles,
    editRoles,
    getRoleById
  } from '@/api/roles/roles'

  export default {
    name: 'RoleForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        roles: {
          id: null,
          name: '',
          display_name: ''
        },
        disabled: false,
        loading: false,
      }
    },
    methods: {
      handleSubmit() {
        const valid = this.$refs.refFormObserver.validate();
        if (valid) {
          this.disabled = true;
          this.loading = true;
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
          }, 1000)

          if (this.id) {
            this.roles.id = this.id;
            // eslint-disable-next-line no-unused-vars
            editRoles(this.roles).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addRoles(this.roles).then(res => {
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchRoleById() {
        getRoleById(this.id).then(res => {
          this.roles = res.data.data;
        })
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchRoleById()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>