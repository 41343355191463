<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <validation-provider
          #default="validationContext"
          name="Insurance Actual Price"
          rules="required"
      >
        <b-form-group label="Insurance Actual Price" for="actual_price">
          <b-form-input autofocus v-model="insurance.actual_price"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="Insurance Market Price"
          rules="required"
      >
        <b-form-group label="Insurance Market Price" for="market_price">
          <b-form-input autofocus v-model="insurance.market_price"></b-form-input>
          <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="Start Date"
          rules="required"
      >
        <label>{{$t('message.Start_date')}}</label>
        <b-form-datepicker id="start_date-datepicker" v-model="insurance.start_date" class="mb-2"></b-form-datepicker>
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="End Date"
          rules="required"
      >
        <label>{{$t('message.End_date')}}</label>
        <b-form-datepicker id="expire_date-datepicker" v-model="insurance.end_date" class="mb-2"></b-form-datepicker>
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>

      <label class="mt-2">{{$t('message.Tours')}}</label>
      <b-form-select
          v-model="insurance.tour_id"
          :options="tours"
          text-field="name"
          value-field="id"
          style="height: 40px"
          class="w-100"
      />

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import {addInsurance, editInsurance, getInsuranceById} from "@/api/insurance";
// import { getBranchById } from '@/api/branchs/branchs'

export default {
  name: 'InsuranceForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:{
    tours:'',
    id:{
      default:null
    }
  },
  data() {
    return {
      required,
      alphaNum,
      insurance: {
        id: null,
        actual_price: '',
        market_price:'',
        start_date:'',
        end_date:'',
        tour_id:''
      },
    }
  },
  methods: {
    // fetchClients() {
    //   getBranchById().then(res => {
    //     this.insurance = res.data.data
    //   })
    // },
    handleSubmit() {
      const isValid = this.$refs.refFormObserver.validate();
      if (isValid) {
        const form = new FormData()
        form.append('id', this.insurance.id)
        form.append('actual_price', this.insurance.actual_price)
        form.append('market_price', this.insurance.market_price)
        form.append('start_date', this.insurance.start_date)
        form.append('end_date', this.insurance.end_date)
        form.append('expire_date', this.insurance.tour_id)
        this.insurance.id = this.id;
        if (this.id) {
          // eslint-disable-next-line no-unused-vars
          editInsurance(this.insurance).then(res => {
            this.$emit('closeModal')
          })
        } else {
          // eslint-disable-next-line no-unused-vars
          addInsurance(this.insurance).then(res => {
            this.$emit('closeModal')
          })
        }
      }
    },
    fetchInsuranceById() {
      getInsuranceById(this.id).then(res => {
        this.insurance = res.data.data;
      })
    }
  },
  watch: {
    id:{
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchInsuranceById()
          // this.fetchClients()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
