import request from '../../libs/axios'

export function getTourReports(params) {
  return request({
    url:'/api/get-tour-reports',
    method:'get',
    params,
  })
}

export function getTourExcel(params) {
  return request({
    url:'/api/tour-reports',
    method:'get',
    params,
    responseType: 'blob',
  })
}
