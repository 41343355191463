import request from '../../libs/axios'

export function getTours(params){
    return request({
        url: '/api/tours',
        method: 'get',
        params,
    })
}

export function createToursById(){
    return request({
        url:'/api/tours',
        method:'get'
    })
}

export function getTourById(id){
    return request({
        url: `/api/tours/${id}`,
        method: 'get',
    })
}


export function addTours(data){
    return request({
        url: '/api/tours',
        method: 'post',
        data,
    })
}

export function editTours(data){
    return request({
        url: `/api/tours/${data.id}`,
        method: 'put',
        data,
    })
}

export function deleteTours(data){
    return request({
        url: `/api/tours/${data.id}`,
        method: 'delete'
    })
}

export function getToursById(id) {
    return request({
        url: `/api/tours/${id}`,
        method:'get',
    })
}

export function generateExcel(params) {
    return request({
        url:'/api/tour-reports',
        method:'get',
        params
    });
}
