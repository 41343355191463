import request from '../../libs/axios'

export function getInsurance(params){
    return request({
        url: '/api/insurance',
        method: 'get',
        params,
    })
}

export function addInsurance(data){
    return request({
        url: '/api/insurance',
        method: 'post',
        data,
    })
}

export function updateInsurance(data){
    return request({
        url: `/api/insurance/${data.id}`,
        method: 'put',
        data,
    })
}

export function deleteInsurance(data){
    return request({
        url: `/api/insurance/${data.id}`,
        method: 'delete'
    })
}

export function getInsuranceById(id) {
    return request({
        url: `/api/insurance/${id}`,
        method:'get',
    })
}

export function showInsurance(id) {
    return request({
        url: `/api/insurance/${id}`,
        method:'get',
    })
}

export function insuranceReportExcel(params){
    return request({
        url:'/api/insurance-excel-reports',
        method:'get',
        params,
        responseType: 'blob',
    })
}
